<template>
  <b-form v-on:submit.prevent="registerSchool">
    <!-- begin: personal details      -->
    <h5 class="text-muted mb-10">{{ $t("EMPLOYEEFORM.PERSONAL") }}</h5>
    <div class="d-flex flex-grow-1">
      <!-- begin: First Name Input -->
      <b-form-group
        label="First Name"
        class="mr-4"
        label-class="font-weight-bolder"
      >
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          v-model="$v.form.firstName.$model"
          :state="validateState('firstName')"
          placeholder="First Name"
        ></b-form-input>
        <!-- end: First Name Input -->

        <b-form-invalid-feedback v-if="!$v.form.firstName.required">
          {{ $t("REGISTER.REQUIRED") }}
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.firstName.maxLength">
          Must not be more than
          {{ $v.form.firstName.$params.maxLength.max }} characters
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.firstName.minLength">
          Must be at least
          {{ $v.form.firstName.$params.minLength.min }} characters
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- begin: Last Name Input -->
      <b-form-group
        label="Last Name"
        class="mr-4"
        label-class="font-weight-bolder"
      >
        <b-form-input
          class="form-control form-control-solid h-auto py-5 px-6"
          v-model="$v.form.lastName.$model"
          :state="validateState('lastName')"
          placeholder="Last Name"
        ></b-form-input>
        <!-- end: First Name Input -->

        <b-form-invalid-feedback v-if="!$v.form.lastName.required">
          {{ $t("REGISTER.REQUIRED") }}
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.lastName.maxLength">
          Must not be more than
          {{ $v.form.lastName.$params.maxLength.max }} characters
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.lastName.minLength">
          Must be at least
          {{ $v.form.lastName.$params.minLength.min }} characters
        </b-form-invalid-feedback>
      </b-form-group>
    </div>

    <!-- begin: Email Input -->
    <b-form-group label="Email" label-class="font-weight-bolder">
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
        :placeholder="$t('EMPLOYEESGENERAL.MESSAGE.EMAIL')"
      ></b-form-input>

      <b-form-invalid-feedback>
        {{ $t("REGISTER.REQUIRED2") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- begin: Password Input -->

    <b-form-group
      :label="$t('LOGIN.PASSWORD')"
      label-class="font-weight-bolder"
    >
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="password"
        v-model="$v.form.password.$model"
        :state="validateState('password')"
        :placeholder="$t('LOGIN.PASSWORD')"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.password.required">
        {{ $t("LOGIN.REQUIRED2") }}
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.password.minLength">
        Password must be at least 6 characters
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- end: personal details      -->

    <!-- begin: school details      -->

    <h5 class="text-muted my-10">School</h5>

    <!-- begin: User Type Input -->
    <b-form-group label="You Are ?" label-class="font-weight-bolder">
      <b-form-select
        class="form-control form-control-solid h-auto py-5 px-6"
        v-model="form.schoolEmpTypeId"
        :options="schoolEmpType"
      ></b-form-select>
    </b-form-group>

    <!--school name  -->
    <b-form-group label="School Name" label-class="font-weight-bolder">
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="text"
        v-model="$v.form.schoolName.$model"
        :state="validateState('schoolName')"
        placeholder="School Name"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.schoolName.required">
        School Name Required
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.schoolName.maxLength">
        Must not be more than 255 characters
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.schoolName.minLength">
        Must be at least
        {{ $v.form.schoolName.$params.minLength.min }} characters
      </b-form-invalid-feedback>
    </b-form-group>

    <!--School Code  -->
    <b-form-group label="School Code" label-class="font-weight-bolder">
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="text"
        v-model="$v.form.schoolCode.$model"
        :state="validateState('schoolCode')"
        aria-describedby="company-no-input-live-feedback"
        placeholder="School Code"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.schoolCode.required">
        This school code is required
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.schoolCode.maxLength">
        Must not be more than 30 characters
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.schoolCode.minLength">
        Must be at least 3 characters
      </b-form-invalid-feedback>
    </b-form-group>

    <!--School email  -->
    <b-form-group label="School Email" label-class="font-weight-bolder">
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="text"
        v-model="$v.form.schoolEmail.$model"
        :state="validateState('schoolEmail')"
        placeholder="example@example.com"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.schoolEmail.required">
        {{ $t("REGISTER.REQUIRED3") }}
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.schoolEmail.email">
        Must use email format: example@example.com
      </b-form-invalid-feedback>
    </b-form-group>

    <!--company contact  -->
    <b-form-group label="School Contact No" label-class="font-weight-bolder">
      <b-form-input
        class="form-control form-control-solid h-auto py-5 px-6"
        type="text"
        v-model="$v.form.schoolContact.$model"
        :state="validateState('schoolContact')"
        placeholder="Example: 0123456789"
      ></b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.schoolContact.required">
        Required
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.schoolContact.minLength">
        Must be minimum of 9 digits
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.schoolContact.maxLength">
        Must not be more than 15 digits
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- end: company details      -->

    <!--begin::Action-->
    <div
      class="form-group d-flex flex-wrap flex-center justify-content-between"
    >
      <button
        v-on:click="$router.push('login')"
        class="btn btn-light font-weight-bold font-size-h6 px-9 py-4 my-3"
      >
        {{ $t("BUTTON.CANCEL") }}
      </button>
      <button
        type="submit"
        ref="school_registration_submit"
        class="btn btn-primary font-weight-bolder font-size-h6 px-9 py-4 my-3"
      >
        {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
      </button>
    </div>
    <!--end::Action-->
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  minLength,
  required,
  maxLength,
} from "vuelidate/lib/validators";
import { REGISTER } from "@/modules/auth/store/register/employer_registration";
import swal from "sweetalert";
export default {
  name: "RegisterSchool",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        schoolName: "",
        schoolCode: "",
        schoolContact: "",
        schoolEmail: "",
        schoolEmpTypeId: "1",
      },
      schoolEmpType: [
        { value: "1", text: "Teacher" },
        { value: "2", text: "Staff" },
      ],
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      lastName: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(30),
      },
      schoolEmail: {
        required,
        email,
        maxLength: maxLength(255),
      },
      schoolName: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(5),
      },
      schoolCode: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30),
      },
      schoolContact: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(15),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        schoolName: null,
        schoolEmail: null,
        schoolCode: null,
        schoolContact: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    /**
     * @param error
     */
    validationError(error) {
      const val = error.response.data.errors;
      const h = this.$createElement;
      const password = val["password"]
        ? val["password"].map((id) => {
            return h("p", { class: ["text-center"] }, id);
          })
        : [h("p", { class: ["text-center"] })];
      const messageVNode = h("div", { class: ["foobar"] }, [
        h("p", { class: ["text-center"] }, [val["email"]]),
        h("p", { class: ["text-center"] }, [val["company_email"]]),
        ...password,
      ]);
      this.$bvModal.msgBoxOk(messageVNode, {
        title: "Please check the entered form",
        centered: true,
        okVariant: "danger",
        bodyClass: "h4",
        footerBgVariant: "light",
      });
    },

    registerSchool() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const first_name = this.$v.form.firstName.$model;
      const last_name = this.$v.form.lastName.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const school_name = this.$v.form.schoolName.$model;
      const school_code = this.$v.form.schoolCode.$model;
      const school_contact = this.$v.form.schoolContact.$model;
      const school_email = this.$v.form.schoolEmail.$model;

      // set spinner to submit button
      const submitButton = this.$refs["school_registration_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(REGISTER, {
          email: email,
          password: password,
          first_name: first_name,
          last_name: last_name,
          school_name: school_name,
          school_code: school_code,
          school_contact: school_contact,
          school_email: school_email,
          user_type: this.form.schoolEmpTypeId,
        })
        .then(() => {
          swal({
            title: "Registration successful",
            text: "Please Login using email and password",
            icon: "success",
          });
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          // Handles other than validation errors.
          console.error("Error!", error.response);
          console.log("Error", error.response.data);
          if (!error.response.data.errors)
            swal("Registration Failed", "Server Error detected.", "error");
          else {
            this.validationError(error);
          }
        })
        .finally(() =>
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          )
        );
    },
  },
};
</script>

<style scoped></style>
